import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import DocumentForm from '@/components/v1/forms/DocumentForm.jsx';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';

const DocumentEditorModal = ({ onClose, document, presets }) => {
    const defaultValues = { public: false, type: 'other', ...document };
    const form = useForm(defaultValues);

    const handleDocumentEditor = (event) => {
        event.preventDefault();
        if (document?.id) {
            form.submit('put', route('documents.update', { document: document.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            form.submit('post', route('documents.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Document {document?.id ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een document in.</Typography>

            <DocumentForm className={'mt-5'} form={form} presets={presets} onSubmit={handleDocumentEditor} />

            <Box mt={32} display={'flex'} gridGap={24}>
                <Button onClick={handleDocumentEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Document {document?.id ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default DocumentEditorModal;

import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';
import Select from '@/components/v1/Select.jsx';

const CollateralObjectForm = ({ form, onSubmit, ...properties }) => {
    const options = {
        fetch_types: [
            {
                title: 'Woning-waarde (Matrixian)',
                value: 'matrixian_home_value',
            },
            {
                title: 'Referentiewoningen (Matrixian)',
                value: 'matrixian_estate_references',
            },
            {
                title: 'Woning-waarde (Altum AI)',
                value: 'altum_ai_avm',
            },
            {
                title: 'Hypotheekinformatie (Kadaster)',
                value: 'kadaster_mortgage',
            },
            {
                title: 'Eigendomsinformatie (Kadaster)',
                value: 'kadaster_ownership',
            },
        ],
    };

    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Type*</Typography>

                <Select name={'types'} value={data?.types} options={options.fetch_types} onChange={handleFormChange} multiple />

                {errors?.type && <Typography color={'error.base'}>{errors?.type}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default CollateralObjectForm;

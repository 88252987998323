import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';

const UserForm = ({ form, onSubmit, presets, existingUser, hidden = [], ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Naam*</Typography>

                    <TextField name={'name'} type={'text'} value={data?.name} error={errors?.name} onChange={handleFormChange} />

                    {errors?.name && <Typography color={'error.base'}>{errors.name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Token*</Typography>

                    <TextField name={'token'} type={'text'} value={data?.token} error={errors?.token} onChange={handleFormChange} />

                    {errors?.token && <Typography color={'error.base'}>{errors.token}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Primaire kleur*</Typography>

                    <TextField name={'primary_color'} type={'text'} placeholder={'#123456'} value={data?.primary_color} error={errors?.primary_color} onChange={handleFormChange} />

                    {errors?.primary_color && <Typography color={'error.base'}>{errors.primary_color}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Primaire textkleur*</Typography>

                    <TextField name={'primary_text_color'} placeholder={'#123456'} value={data?.primary_text_color} error={errors?.primary_text_color} onChange={handleFormChange} />

                    {errors?.primary_text_color && <Typography color={'error.base'}>{errors.primary_text_color}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Secundaire kleur*</Typography>

                    <TextField name={'secondary_color'} placeholder={'#123456'} value={data?.secondary_color} error={errors?.secondary_color} onChange={handleFormChange} />

                    {errors?.secondary_color && <Typography color={'error.base'}>{errors.secondary_color}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Secundaire textkleur*</Typography>

                    <TextField name={'secondary_text_color'} placeholder={'#123456'} value={data?.secondary_text_color} error={errors?.secondary_text_color} onChange={handleFormChange} type={'text'} />

                    {errors?.secondary_text_color && <Typography color={'error.base'}>{errors.secondary_text_color}</Typography>}
                </div>

                <div className={'col-span-2 flex flex-col gap-2'}>
                    <Typography>URL Bedanktpagina*</Typography>

                    <TextField name={'redirect_url'} placeholder={'https://capilex.com/bedankt-voor-uw-aanvraag'} value={data?.redirect_url} error={errors?.redirect_url} onChange={handleFormChange} type={'text'} />

                    {errors?.redirect_url && <Typography color={'error.base'}>{errors.redirect_url}</Typography>}
                </div>
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default UserForm;

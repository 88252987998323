import { useEffect, useRef } from 'react';

// Custom hook to run an effect only after the component has mounted
const useEffectAfterMount = (callback, dependencies) => {
    const hasMounted = useRef(false);

    // Effect to check if the component has mounted before running the callback
    useEffect(() => {
        if (hasMounted.current) {
            // Run the callback if the component has already mounted
            return callback();
        } else {
            // Set the hasMounted flag to true after the initial render
            hasMounted.current = true;
        }
    }, dependencies); // The effect depends on the provided dependencies
};

export default useEffectAfterMount;

const click = {
    whileTap: {
        scale: 0.95,
    },
    transition: {
        type: 'spring',
        stiffness: 400,
        damping: 16,
    },
};

const loader = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        delay: 0.2,
        duration: 0.2,
    },
};

const backdrop = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        duration: 0.2,
    },
};

const modal = (fadeIn) => ({
    initial: {
        opacity: 0,
        y: fadeIn === 'top' ? -100 : fadeIn === 'bottom' ? 100 : 0,
        x: fadeIn === 'left' ? -100 : fadeIn === 'right' ? 100 : 0,
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
    },
    exit: {
        opacity: 0,
        y: fadeIn === 'top' ? -100 : fadeIn === 'bottom' ? 100 : 0,
        x: fadeIn === 'left' ? -100 : fadeIn === 'right' ? 100 : 0,
    },
    transition: {
        duration: 0.2,
    },
});

export { click, modal, backdrop, loader };

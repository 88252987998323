import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const CurrencyField = ({ name, onChange, value: propValue, className, ...properties }) => {
    const [displayValue, setDisplayValue] = useState('');
    const inputRef = useRef(null);

    const formatValue = (value) => {
        if (value === '') return '';

        // Verwijder alle niet-numerieke karakters behalve komma's
        let cleanValue = value.replace(/[^\d,]/g, '');

        // Behoud slechts één komma
        const commaIndex = cleanValue.indexOf(',');
        if (commaIndex !== -1) {
            cleanValue = cleanValue.slice(0, commaIndex + 1) + cleanValue.slice(commaIndex + 1).replace(/,/g, '');
        }

        // Split de waarde in geheel en decimaal deel
        let [integerPart, decimalPart] = cleanValue.split(',');

        // Verwijder voorloopnullen van het gehele deel
        integerPart = integerPart.replace(/^0+/, '') || '0';

        // Formateer het gehele deel met punten als duizendtalscheider
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Beperk decimalen tot 2
        if (decimalPart) {
            decimalPart = decimalPart.slice(0, 2);
        }

        // Combineer geheel en decimaal deel
        return decimalPart !== undefined ? `${integerPart},${decimalPart}` : integerPart;
    };

    const parseValue = (value) => {
        // Verwijder punten en vervang komma door punt voor JavaScript
        return value.replace(/\./g, '').replace(',', '.');
    };

    useEffect(() => {
        // Update displayValue wanneer propValue verandert
        setDisplayValue(formatValue(propValue ? propValue.toString().replace('.', ',') : ''));
    }, [propValue]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const formattedValue = formatValue(inputValue);

        // Bewaar de cursorpositie
        const cursorPosition = event.target.selectionStart;

        setDisplayValue(formattedValue);

        const parsedValue = parseValue(formattedValue);
        onChange({ target: { name, value: parsedValue } });

        // Stel de cursor terug op de juiste positie na de update
        setTimeout(() => {
            if (inputRef.current) {
                const newCursorPosition = cursorPosition + (formattedValue.length - inputValue.length);
                inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    return <input ref={inputRef} type='text' className={twMerge('text-black px-4 h-14 w-full bg-white border rounded-lg border-grey-200 focus:border-primary-500 bg-white focus:outline-none', className)} value={displayValue} onChange={handleInputChange} {...properties} />;
};

export default CurrencyField;

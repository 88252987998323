import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const drawerBackdropMotion = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        type: 'linear',
        duration: 0.2,
    },
};

const drawerMenuMotion = {
    initial: {
        opacity: 0,
        x: 240,
        overflow: 'hidden',
    },
    animate: {
        opacity: 1,
        x: 0,
        transitionEnd: { overflow: 'auto' },
    },
    exit: {
        opacity: 0,
        x: 240,
        overflow: 'hidden',
    },
    transition: {
        type: 'linear',
        duration: 0.2,
    },
};

const Drawer = ({ onClose, children, ...properties }) => {
    useEffect(() => {
        document.body.style.overflowY = 'clip';
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.style.removeProperty('overflowY');
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClose = () => {
        if (!onClose) return;

        onClose();
    };

    const handleKeyDown = (event) => {
        if (event.keyCode !== 27) return;
        if (!onClose) return;

        onClose();
    };

    return (
        <StyledDrawer>
            <StyledDrawerBackdrop {...drawerBackdropMotion} onClick={handleClose} />

            <StyledDrawerMenu {...drawerMenuMotion} {...properties}>
                <div className={'h-screen overflow-auto p-10'}>{children}</div>
            </StyledDrawerMenu>
        </StyledDrawer>
    );
};

const StyledDrawer = styled(motion.div)`
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
`;

const StyledDrawerBackdrop = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    z-index: -1;
`;

const StyledDrawerMenu = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    max-width: 480px;
    background-color: ${({ theme }) => theme.colors.background.base};
    box-shadow: 0px 25px 45px 5px rgba(0, 0, 0, 0.13);
    overflow: auto;
`;

export default Drawer;

import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { ArrowLeftOnRectangleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const UserImpersonationStopDialog = ({ onClose, user }) => {
    const [processing, setProcessing] = useState(false);
    const handleImpersonationLeave = () => {
        setProcessing(true);

        router.visit(route('impersonate.leave'), {
            onFinish: () => setProcessing(false),
            onSuccess: () => onClose(),
        });
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Terug naar eigen account
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je terug wilt gaan naar je eigen account?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleImpersonationLeave}>
                    <ArrowLeftOnRectangleIcon width={24} />
                    <span className={'font-bold'}>Terug naar eigen account</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default UserImpersonationStopDialog;

import Modal from '@/components/v1/Modal';
import Button from '@/components/v1/Button';
import { useForm } from '@inertiajs/react';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import SelectField from '@/components/v1/fields/SelectField.jsx';
import Checkbox from '@/components/v1/Checkbox.jsx';
import Label from '@/components/v1/Label.jsx';
import ErrorMessage from '@/components/v1/ErrorMessage.jsx';

const SurityAgreementSelectorModal = ({ onClose, person }) => {
    const templateTypes = [
        { title: 'Particuliere borg', value: 'personal_surity_agreement' },
        { title: 'Zakelijke borg', value: 'business_surity_agreement' },
    ];

    const form = useForm({
        template_type: templateTypes[0].value,
        company_id: person?.company_id,
        person_id: person?.id,
        as_person: false,
    });

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        form.setData(name, value);
    };

    const handleFormSubmit = () => {
        form.submit('post', route('agreement.template.build', { template_type: form.data?.template_type }), {
            onSuccess: onClose,
            preserveScroll: true,
        });
    };

    return (
        <Modal className={'flex flex-col gap-8'} onClose={onClose}>
            <h1 className={'text-2xl font-bold'}>Overeenkomst van borgtocht</h1>

            <form className={'flex flex-col gap-5'}>
                <div className={'flex flex-col gap-2'}>
                    <Label>Type*</Label>

                    <SelectField name={'template_type'} value={form.data?.template_type} options={templateTypes} onChange={handleFormChange} />

                    <ErrorMessage message={form.errors?.template_type} />
                </div>

                <div className='flex items-center space-x-2'>
                    <Checkbox id='as_person' name='as_person' checked={form.data?.as_person} onChange={handleFormChange} label='Als natuurlijk persoon' />
                </div>
            </form>

            <div className={'mt-4 flex flex-col gap-5'}>
                <div className={'flex gap-4'}>
                    <Button onClick={handleFormSubmit} loading={form.processing}>
                        <PlusIcon width={24} />
                        <span className={'font-bold'}>Overeenkomst opmaken</span>
                    </Button>

                    <Button className={'bg-grey-150'} onClick={onClose}>
                        <XCircleIcon width={24} />
                        <span className={'font-bold'}>Sluiten</span>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SurityAgreementSelectorModal;

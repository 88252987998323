import { PhoneInput } from 'react-international-phone';
import { twMerge } from 'tailwind-merge';
import 'react-international-phone/style.css';

const PhoneField = ({ name, value, onChange, className, ...properties }) => {
    const handleChange = (value) => {
        onChange && onChange({ target: { name, value } });
    };

    return <PhoneInput onChange={handleChange} value={value || ''} defaultCountry={'nl'} forceDialCode={true} className={twMerge('w-full', className)} {...properties} />;
};

export default PhoneField;

export default {
    breakpoints: {
        xs: '0px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1400px',
    },
    colors: {
        background: {
            base: '#f8f8f8',
            variant: '#FFFFFF',
            contrast: '#302E2F',
        },
        primary: {
            base: '#A9C1B7',
            variant: '#C2D0C9',
            contrast: '#302E2F',
        },
        secondary: {
            base: '#B5C5CE',
            variant: '#E8F1F6',
            contrast: '#302E2F',
        },
        tertiary: {
            base: '#E0DEDB',
            variant: '#DFDEDC',
            contrast: '#302E2F',
        },
        grey: ['#F5F5F5', '#E8E8E8', '#DCDCDC', '#D3D3D3', '#C8C8C8', '#BEBEBE', '#B0B0B0', '#A8A8A8', '#989898', '#888888'],
        success: {
            base: '#70CD61',
            variant: '#c6ebc0',
            contrast: '#404040',
        },
        warning: {
            base: '#FF9900',
            variant: '#fdba74',
            contrast: '#404040',
        },
        error: {
            base: '#c92a2a',
            variant: '#e1a6a6',
            contrast: '#404040',
        },
        backdrop: 'rgba(0, 0, 0, .5)',
        gradients: {
            sidebar: 'linear-gradient(rgb(255, 255, 255) 96%, rgb(247, 247, 247))',
        },
    },
    shadows: {
        sidebar: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        panel: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
        dropdown: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    fontWeights: {
        thin: 100,
        light: 300,
        regular: 400,
        bold: 700,
        black: 900,
    },
    fontSizes: {
        xs: '.75rem',
        sm: '1rem',
        md: '1.25rem',
        lg: '1.5rem',
        xl: '1.75rem',
        xxl: '2rem',
    },
    space: [],
    radii: [],
};

import { AnimatePresence, motion } from 'framer-motion';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

const variants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
};

const ErrorMessage = ({ message, className = '', ...properties }) => (
    <AnimatePresence>
        {message && (
            <motion.div variants={variants} initial='initial' animate='animate' exit='exit' transition={{ duration: 0.2, ease: 'easeInOut' }} className='flex items-center gap-2 text-sm text-[red]'>
                <ExclamationTriangleIcon className={'size-5'} />

                <p className={twMerge('text-sm', className)} {...properties}>
                    {message}
                </p>
            </motion.div>
        )}
    </AnimatePresence>
);

export default ErrorMessage;

import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import CompanyContactForm from '@/components/v1/forms/CompanyContactForm.jsx';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';

const CompanyContactEditorModal = ({ onClose, company, presets, contact }) => {
    const defaultValues = { company_id: company.id, ...contact };
    const form = useForm(defaultValues);

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        if (contact) {
            form.submit('put', route('users.update', { user: contact.id }), {
                onSuccess: () => onClose(),
            });
        } else {
            form.submit('post', route('users.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Contactpersoon {contact ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een contactpersoon in.</Typography>

            <CompanyContactForm className={'mt-5'} form={form} presets={presets} onSubmit={handleApplicationEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Contactpersoon {contact ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default CompanyContactEditorModal;

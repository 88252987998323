import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { ArrowPathIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const UserResendInviteDialog = ({ onClose, user }) => {
    const [processing, setProcessing] = useState(false);
    const handleApplicationDelete = () => {
        setProcessing(true);
        router.post(
            route('users.resend-invite', { user: user.id }),
            {},
            {
                onFinish: () => setProcessing(false),
                onSuccess: () => onClose(),
                preserveScroll: true,
            },
        );
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography fontSize={'md'} className={'font-semibold'} textAlign={'center'}>
                Uitnodiging opnieuw versturen
            </Typography>

            <Typography mt={8} textAlign={'center'}>
                Weet je zeker dat je de uitnodiging naar deze gebruiker opnieuw wilt versturen?
            </Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationDelete}>
                    <ArrowPathIcon width={24} />
                    <span className={'font-semibold'}>Uitnodiging opnieuw versturen</span>
                </Button>

                <Button onClick={onClose} backgroundColor={'grey.1'}>
                    <XCircleIcon width={24} />
                    <span className={'font-semibold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default UserResendInviteDialog;

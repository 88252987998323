import { motion } from 'framer-motion';
import * as Motioner from '../../../utils/MotionerUtil.js';
import { twMerge } from 'tailwind-merge';

const OptionsField = ({ name, value, onChange, options, className, style, ...properties }) => {
    const handleOptionClick = (option) => {
        onChange({ target: { name, value: option.value } });
    };

    return (
        <motion.div className={twMerge('flex items-center flex-wrap gap-4', className)} {...properties}>
            {options?.map((option, index) => {
                const isActive = value === option.value;

                return (
                    <motion.div key={`option-${index}`} className={`relative flex h-14 cursor-pointer items-center gap-2 rounded-md px-4 font-bold hover:bg-grey-200`} type={'button'} onClick={() => handleOptionClick(option)} {...Motioner.click}>
                        {isActive && <motion.div className={'active-option absolute left-0 top-0 size-full rounded-md bg-secondary-500'} style={style} layoutId={`option-field-${name}`} />}

                        <p className={'relative'}>{option.title}</p>
                    </motion.div>
                );
            })}
        </motion.div>
    );
};

export default OptionsField;

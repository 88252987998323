import styled from 'styled-components';

const RadioField = ({ title, value, disabled, className, ...properties }) => (
    <div className={`flex w-full gap-2 ${disabled ? 'grayscale-1' : 'cursor-pointer'} ${className}`} {...properties}>
        <div className={`aspect-square h-7 rounded-md border border-solid border-primary-500 p-1 ${!disabled && 'hover:bg-primary-300'} ${value && 'bg-primary-150'}`}>{value && <div className={'aspect-square h-full rounded-full bg-primary-500'} />}</div>

        <p>{title}</p>
    </div>
);

const Radio = styled.input`
    margin: 0;
    appearance: none;
    min-width: 28px;
    height: 28px;
    border: 2px solid ${({ theme: { colors } }) => colors.primary.base};
    border-radius: 50%;
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    filter: grayscale(${({ disabled }) => (disabled ? 1 : 0)});
    opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};
    transition: filter ease-in-out 100ms;

    &::after {
        content: '';
        display: block;
        border-radius: 50%;
        margin-top: 6px;
        margin-left: 6px;
        width: 12px;
        height: 12px;
    }

    &:checked {
        &::after {
            background-color: ${({ theme: { colors } }) => colors.primary.base};
        }
    }

    &:hover {
        filter: brightness(90%);
    }
`;

RadioField.defaultProps = {};

export default RadioField;

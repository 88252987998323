import { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ActivitiesDrawer from '@/components/v1/drawers/ActivitiesDrawer.jsx';

const Drawers = {
    activities: ActivitiesDrawer,
};

export const DrawerContext = createContext();

export const useDrawer = () => {
    const context = useContext(DrawerContext);

    return context;
};

export const DrawerProvider = ({ presets, children }) => {
    const [active, setActive] = useState([]);

    const openDrawer = (id, properties = {}) => {
        let newActive = [...active];

        newActive.push({ id, properties });
        setActive(newActive);
    };

    const closeDrawer = (index) => {
        let newActive = [...active];

        newActive.splice(index, 1);
        setActive(newActive);
    };

    const clearDrawers = () => {
        setActive([]);
    };

    return (
        <DrawerContext.Provider value={{ openDrawer, closeDrawer, clearDrawers }}>
            <AnimatePresence>
                {active.map((drawer, index) => {
                    const Drawer = Drawers[drawer.id];

                    return <Drawer key={`drawer-${index}`} presets={presets} {...drawer.properties} onClose={() => closeDrawer(index)} />;
                })}
            </AnimatePresence>

            {children}
        </DrawerContext.Provider>
    );
};

import Modal from '@/components/v1/Modal.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box.jsx';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import UserForm from '@/components/v1/forms/UserForm.jsx';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';

const UserEditorModal = ({ onClose, presets, user, company, hidden }) => {
    const initialValues = {
        company_id: company?.id,
        salutation: presets.salutations[0],
        ...user,
    };
    const form = useForm(initialValues);

    const handleUserEditor = (event) => {
        event.preventDefault();

        if (user?.id) {
            form.submit('put', route('users.update', { user: user.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            form.submit('post', route('users.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Gebruiker {user ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een gebruiker in.</Typography>

            <UserForm className={'mt-5'} form={form} presets={presets} hidden={hidden} onSubmit={handleUserEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleUserEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Gebruiker {user ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default UserEditorModal;

import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';

const ErrorDialog = ({ title, description, ...properties }) => (
    <Dialog {...properties}>
        <Typography>{title}</Typography>

        <Typography>{description}</Typography>
    </Dialog>
);

export default ErrorDialog;

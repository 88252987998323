import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';
import SelectField from '@/components/v1/fields/SelectField.jsx';
import PhoneField from '@/components/v1/fields/PhoneField.jsx';

const CompanyContactForm = ({ form, onSubmit, presets, ...properties }) => {
    const options = {
        label_types: presets.label_types.map((label_type) => ({
            title: label_type,
            value: label_type,
        })),
    };
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Voornaam*</Typography>

                <TextField name={'first_name'} type={'text'} value={data?.first_name} error={errors?.first_name} onChange={handleFormChange} />

                {errors?.first_name && <Typography color={'error.base'}>{errors.first_name}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>Achternaam*</Typography>

                <TextField name={'last_name'} type={'text'} value={data?.last_name} error={errors?.last_name} onChange={handleFormChange} />

                {errors?.last_name && <Typography color={'error.base'}>{errors.last_name}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>E-mailadres*</Typography>

                <TextField name={'email'} type={'text'} value={data?.email} error={errors?.email} onChange={handleFormChange} />

                {errors?.email && <Typography color={'error.base'}>{errors.email}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>Telefoonnummer*</Typography>

                <PhoneField name={'phone'} type={'text'} value={data?.phone} error={errors?.phone} onChange={handleFormChange} />

                {errors?.phone && <Typography color={'error.base'}>{errors.phone}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography>Label*</Typography>

                <SelectField name={'label_type'} value={data?.label_type} error={errors?.label_type} options={options.label_types} onChange={handleFormChange} />

                {errors?.label_type && <Typography color={'error.base'}>{errors.label_type}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default CompanyContactForm;

import Dialog from '@/components/v1/Dialog.jsx';
import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { router } from '@inertiajs/react';

const ApplicationArchiveDialog = ({ onClose, application }) => {
    const [processing, setProcessing] = useState(false);

    const handleApplicationArchive = () => {
        setProcessing(true);
        if (application.archived_at) {
            router.put(route('applications.unarchive', { application: application.id }), null, {
                onFinish: () => setProcessing(false),
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            router.put(route('applications.archive', { application: application.id }), null, {
                onFinish: () => setProcessing(false),
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Dialog onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Aanvraag {application.archived_at ? 'dearchiveren' : 'archiveren'}
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je deze aanvraag wilt {application.archived_at ? 'dearchiveren' : 'archiveren'}?</Typography>

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationArchive}>
                    {application.archived_at ? <ArchiveBoxXMarkIcon width={24} /> : <ArchiveBoxArrowDownIcon width={24} />}
                    <span className={'font-bold'}>Aanvraag {application.archived_at ? 'dearchiveren' : 'archiveren'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Dialog>
    );
};

export default ApplicationArchiveDialog;

import { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const useRipple = () => {
    const [ripples, setRipples] = useState([]);

    const create = useCallback((event) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const size = Math.max(rect.width, rect.height) * 2;

        const newRipple = {
            x,
            y,
            size,
            id: new Date().getTime(),
        };

        setRipples((prevRipples) => [...prevRipples, newRipple]);
    }, []);

    const remove = useCallback((id) => {
        setRipples((prevRipples) => prevRipples.filter((ripple) => ripple.id !== id));
    }, []);

    const render = useCallback(
        () => (
            <div className='pointer-events-none absolute inset-0 overflow-hidden'>
                <AnimatePresence>
                    {ripples.map((ripple) => (
                        <motion.span
                            key={ripple.id}
                            className='absolute rounded-full bg-white opacity-30'
                            style={{
                                left: ripple.x - ripple.size / 2,
                                top: ripple.y - ripple.size / 2,
                                width: ripple.size,
                                height: ripple.size,
                            }}
                            initial={{ scale: 0, opacity: 0.5 }}
                            animate={{ scale: 1, opacity: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            onAnimationComplete={() => remove(ripple.id)}
                        />
                    ))}
                </AnimatePresence>
            </div>
        ),
        [ripples, remove],
    );

    return { create, render };
};

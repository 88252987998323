import prettyBytes from 'pretty-bytes';
import moment from 'moment-timezone';
import 'moment/dist/locale/nl';

moment().locale('nl');
moment().tz('Europe/Amsterdam');

const dateFromNow = (date) => moment(date).fromNow();
const date = (value) => moment(value).format('DD-MM-YYYY');
const dateTime = (value) => moment(value).format('DD-MM-YYYY HH:mm');
const dateLocalised = (value) => moment(value).format('LLL');
const dateShort = (value) => moment(value).format('MMM');
const monthYearShort = (value) => moment(value).format('MMM YY');
const bytes = (bytes) => prettyBytes(bytes);

const getMoment = () => {
    return moment();
};

const countdown = (currentDate, sendIn) => {
    const duration = moment.duration(moment(sendIn).diff(currentDate));
    if (duration.asMilliseconds() < 0) return null;
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};

const greeting = (date) => {
    const hours = date.getHours();

    if (hours >= 6 && hours < 12) return 'Goedemorgen';
    else if (hours >= 12 && hours < 18) return 'Goedemiddag';
    else return 'Goedenavond';
};

const currency = (value, digits = 0) => {
    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: digits,
    });

    return formatter.format(value);
};

const currencyShort = (value) => {
    const formatter = Intl.NumberFormat('nl-NL', { notation: 'compact' });

    return formatter.format(value);
};

const zipCode = (value) => {
    return value?.replace(/(\d{4})([A-Z]{2})/, '$1 $2');
};

const investorState = (value) =>
    ({
        registered: 'Geregistreerd',
        pre_accepted: 'Voorlopige goedkeuring',
        accepted: 'Geaccepteerd',
        declined: 'Afgewezen',
        invested: 'Geinvesteerd',
    }[value]);

const investorColor = (value) =>
    ({
        registered: 'secondary',
        pre_accepted: 'secondary',
        accepted: 'success',
        declined: 'error',
        invested: 'secondary',
    }[value]);

const hubspotPipelineStageColor = (value) =>
    ({
        'Controleren': 'secondary',
        'In beoordeling': 'secondary',
        'Conditioneel voorstel mogelijk': 'secondary',
        'Conditioneel voorstel': 'secondary',
        '2e kans conditioneel voorstel': 'secondary',
        'In onderhandeling': 'secondary',
        'Krediet comité': 'secondary',
        'On hold - wachten op stukken': 'secondary',
        'Bij notaris voor toestemming': 'secondary',
        'Geweigerde toestemming / aanleveren informatie': 'secondary',
        'Bij de notaris voor afwikkeling': 'secondary',
        'Invoeren LD en afwikkeling': 'secondary',
        'Gewonnen': 'success',
        'Verloren ': 'error',
        'Dubbelcheck': 'secondary',
        'Wellicht een borgsteller': 'secondary',
        'Verloren': 'error',
    }[value]);

const fetchType = (value) =>
    ({
        matrixian_home_value: 'Woning-waarde (Matrixian)',
        matrixian_estate_references: 'Referentiewoningen (Matrixian)',
        altum_ai_avm: 'Woning-waarde (Altum AI)',
        kadaster_mortgage: 'Hypotheekinformatie (Kadaster)',
        kadaster_ownership: 'Eigendomsinformatie (Kadaster)',
    }[value]);

const documentType = (value) =>
    ({
        government_id: 'Identificatie',
        contract: 'Contract',
        company_registration: 'KvK uittreksel',
        other: 'Overig',
    }[value]);

const boolean = (value) =>
    ({
        true: 'Ja',
        false: 'Nee',
    }[value]);

const role = (value) =>
    ({
        'admin': 'Administrator',
        'backoffice': 'Backoffice',
        'investor-relations': 'Investor relations',
        'read-only': 'Alleen-lezen',
        'customer': 'Standaard',
        'investor': 'Investeerder',
    }[value]);

const investorType = (value) =>
    ({
        user: 'Persoon',
        company: 'Zakelijk',
    }[value]);

const salutation = (value) =>
    ({
        mr: 'Meneer',
        mrs: 'Mevrouw',
        other: 'Anders',
    }[value]);

const decentSalutation = (value) =>
    ({
        mr: 'heer',
        mrs: 'mevrouw',
        other: 'anders',
    }[value]);

const fullDecentSalutation = (value) =>
    ({
        mr: 'de heer',
        mrs: 'mevrouw',
        other: 'anders',
    }[value]);

const investorSalutation = (value) =>
    ({
        mr: 'Heer',
        mrs: 'Mevrouw',
        other: 'Anders',
    }[value]);

const shortSalutation = (value) =>
    ({
        mr: 'Dhr.',
        mrs: 'Mevr.',
    }[value]);

const contractState = (value) =>
    ({
        cancelled: 'Geannuleerd',
        paused: 'Gepauzeerd',
        queue: 'In wachtrij',
        review: 'Ter beoordeling',
        sent: 'Verstuurd',
        declined: 'Afgewezen',
        complete: 'Compleet',
        expired: 'Verlopen',
    }[value]);

const contractType = (value) =>
    ({
        conditional_loan_proposal: 'Conditioneel voorstel',
        loan_extension_proposal: 'Verlengingsvoorstel',
    }[value]);

const contractColor = (value) =>
    ({
        cancelled: 'secondary',
        paused: 'secondary',
        queue: 'secondary',
        review: 'warning',
        sent: 'secondary',
        complete: 'success',
        declined: 'error',
        expired: 'error',
    }[value]);

const requestedAmountNeededIn = (value) =>
    ({
        five_days: '5 dagen',
        fourteen_days: '14 dagen',
        one_month: '1 maand',
        later: 'Later',
    }[value]);

const collateralObjectState = (value) =>
    ({
        just_in: 'Net binnen',
        fetching: 'Bezig met ophalen',
        fetching_failed: 'Ophalen mislukt',
        fetching_completed: 'Ophalen gelukt',
    }[value]);

const frequency = (value) =>
    ({
        daily: 'Dagelijks',
        weekly: 'Wekelijks',
        monthly: 'Maandelijks',
        quarterly: 'Per kwartaal',
        half_yearly: 'Halfjaarlijks',
        yearly: 'Jaarlijks',
    }[value]);

const dateInput = (value) => moment(value).format('YYYY-MM-DD');

const ownershipType = (value) =>
    ({
        B: 'Er is een gebruiker die tevens (mede)eigenaar is.',
        V: 'Er is een gebruiker die geen (mede)eigenaar is. ',
        P: 'Er is een eigenaar die gebruiker kan zijn.',
    }[value]);

const usageType = (value) =>
    ({
        E: 'In eigendom',
        H: 'In de verhuur',
    }[value]);

const investableAssets = (value) =>
    ({
        0: 'Onbekend',
        100000: '€100k tot €150k',
        150000: '€150k tot €300k',
        300000: '€300k tot €500k',
        500000: '€500k+',
    }[value]);

const loanOverdueVariant = (value) => {
    if (!value || value === 0) {
        return 'success';
    }

    if (value > 0 && value <= 30) {
        return 'warning';
    }

    if (value > 30 && value <= 60) {
        return 'warning';
    }

    return 'error';
};

const loanOverdueTitle = (value) => {
    if (!value || value === 0) {
        return 'Geen';
    }

    return `${value} dagen`;
};

export {
    investorType,
    getMoment,
    countdown,
    dateInput,
    fetchType,
    contractColor,
    contractState,
    contractType,
    investorColor,
    investorState,
    greeting,
    documentType,
    boolean,
    salutation,
    decentSalutation,
    fullDecentSalutation,
    bytes,
    currency,
    currencyShort,
    dateFromNow,
    dateLocalised,
    role,
    date,
    dateTime,
    collateralObjectState,
    dateShort,
    requestedAmountNeededIn,
    shortSalutation,
    investorSalutation,
    hubspotPipelineStageColor,
    frequency,
    ownershipType,
    usageType,
    investableAssets,
    zipCode,
    monthYearShort,
    loanOverdueVariant,
    loanOverdueTitle,
};

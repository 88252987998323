import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const modalBackdropMotion = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        type: 'linear',
        duration: 0.2,
    },
};

const modalMenuMotion = {
    initial: {
        opacity: 0,
        y: '-25vh',
        overflow: 'hidden',
    },
    animate: {
        opacity: 1,
        y: 0,
        overflow: 'visible',
    },
    exit: {
        opacity: 0,
        y: '25vh',
        overflow: 'hidden',
    },
    transition: {
        type: 'linear',
        duration: 0.2,
    },
};

const Modal = ({ onClose, children, ...properties }) => {
    useEffect(() => {
        document.body.style.overflowY = 'clip';
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.style.removeProperty('overflowY');
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClose = () => {
        if (!onClose) return;

        onClose();
    };

    const handleKeyDown = (event) => {
        if (event.keyCode !== 27) return;
        if (!onClose) return;

        onClose();
    };

    return (
        <StyledModal>
            <StyledModalBackdrop {...modalBackdropMotion} onClick={handleClose} />

            <StyledModalMenu {...modalMenuMotion} {...properties}>
                {children}
            </StyledModalMenu>
        </StyledModal>
    );
};

const StyledModal = styled(motion.div)`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 1000;
`;

const StyledModalBackdrop = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    z-index: -1;
`;

const StyledModalMenu = styled(motion.div)`
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    background-color: ${({ theme }) => theme.colors.background.base};
    border-radius: 12px;
    box-shadow: 0px 25px 45px 5px rgba(0, 0, 0, 0.13);
`;

export default Modal;

import TextField from '../fields/TextField';
import Typography from '../Typography';
import Box from '../Box';
import { useMemo, useState } from 'react';
import * as Formatter from '@/utils/FormatterUtil.js';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';
import Select from '@/components/v1/Select';
import AddressField from '@/components/v1/fields/AddressField.jsx';

const PersonForm = ({ form, onSubmit, presets, hidden = [], ...properties }) => {
    const [options, setOptions] = useState({
        salutations: presets.salutations.map((salutation) => ({
            title: Formatter.salutation(salutation),
            value: salutation,
        })),
    });
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Aanhef*</Typography>

                    <Select name={'salutation'} value={data?.salutation} options={options.salutations} onChange={handleFormChange} />

                    {errors?.salutation && <Typography color={'error.base'}>{errors.salutation}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Initialen*</Typography>

                    <TextField name={'initials'} type={'text'} value={data?.initials} error={errors?.initials} onChange={handleFormChange} />

                    {errors?.initials && <Typography color={'error.base'}>{errors.initials}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Voornaam*</Typography>

                    <TextField name={'first_name'} type={'text'} value={data?.first_name} error={errors?.first_name} onChange={handleFormChange} />

                    {errors?.first_name && <Typography color={'error.base'}>{errors.first_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Achternaam*</Typography>

                    <TextField name={'last_name'} type={'text'} value={data?.last_name} error={errors?.last_name} onChange={handleFormChange} />

                    {errors?.last_name && <Typography color={'error.base'}>{errors.last_name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Geboortedatum*</Typography>

                    <TextField name={'date_of_birth'} value={data?.date_of_birth} error={errors?.date_of_birth} onChange={handleFormChange} type={'date'} />

                    {errors?.date_of_birth && <Typography color={'error.base'}>{errors.date_of_birth}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Geboorteplaats*</Typography>

                    <TextField name={'birth_location'} type={'text'} value={data?.birth_location} error={errors?.birth_location} onChange={handleFormChange} />

                    {errors?.birth_location && <Typography color={'error.base'}>{errors.birth_location}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Adres*</Typography>

                    <AddressField value={form.data?.address} onChange={(event) => form.setData('address', event.target.value)} />

                    <p className={'text-sm italic text-grey-900'}>Huidig woonadres van deze persoon.</p>

                    {errors?.address?.street && <Typography color={'error.base'}>{errors.address?.street}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Functie*</Typography>

                    <TextField name={'function'} type={'text'} value={data?.function} error={errors?.function} onChange={handleFormChange} />

                    <p className={'text-sm italic text-grey-900'}>Functie van persoon binnen dit bedrijf.</p>

                    {errors?.function && <Typography color={'error.base'}>{errors.function}</Typography>}
                </div>

                {!hidden.includes('partner') && (
                    <div className={'flex flex-col gap-2'}>
                        <Typography>Partner</Typography>

                        <AutocompleteField name={'partner'} value={data?.partner} model={'Person'} onChange={handleFormChange} renderTitle={(option) => option.full_name} filters={{ company_id: data?.company_id }} exclude={[form?.data?.id]} />

                        <p className={'text-sm italic text-grey-900'}>Gehuwd of sprake van een geregistreerd partnerschap.</p>

                        {errors?.partner?.id && <Typography color={'error.base'}>{errors.partner?.id}</Typography>}
                    </div>
                )}
            </Box>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default PersonForm;

import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';
import TextAreaField from '@/components/v1/fields/TextAreaField.jsx';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';

const CollateralObjectForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Entiteit*</Typography>

                <AutocompleteField name={'entity'} value={data?.entity} model={'Entity'} onChange={handleFormChange} renderTitle={(option) => option.name} />

                {errors?.entity?.id && <Typography color={'error.base'}>{errors.entity.id}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={24}>Beschrijving</Typography>

                <TextAreaField name={'description'} value={data?.description} error={errors?.description} onChange={handleFormChange} />

                {errors?.description && <Typography color={'error.base'}>{errors.description}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default CollateralObjectForm;

import { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { v4 as uuid } from 'uuid';

const PopupContext = createContext();

// Custom hook to access the popup context
const usePopup = () => {
    return useContext(PopupContext);
};

// Provider component for managing popup state
const PopupProvider = ({ children, presets }) => {
    const [popups, setPopups] = useState([]);

    // Function to add a new popup to the state
    const push = (Component, properties = {}) => {
        setPopups((previousPopups) => [...previousPopups, { id: uuid(), Component, properties }]);
    };

    // Function to remove a popup from the state
    const pop = (id) => {
        setPopups((previousPopups) => previousPopups.filter((popup) => popup.id !== id));
    };

    // Providing the popup context to the children components
    return (
        <PopupContext.Provider value={{ push, pop }}>
            <AnimatePresence>
                {popups.map((popup) => {
                    const { Component, properties } = popup;

                    return <Component key={`popup-${popup.id}`} presets={presets} onClose={() => pop(popup.id)} {...properties} />;
                })}
            </AnimatePresence>

            {children}
        </PopupContext.Provider>
    );
};

export { usePopup, PopupProvider };

import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import CurrencyField from '@/components/v1/fields/CurrencyField.jsx';
import OptionsField from '@/components/v1/fields/OptionsField.jsx';
import { useMemo } from 'react';
import * as Formatter from '@/utils/FormatterUtil.js';
import AutocompleteField from '@/components/v1/fields/AutocompleteField.jsx';

const ApplicationForm = ({ form, onSubmit, presets, ...properties }) => {
    const options = {
        application_days: presets?.application_days?.map((application_day) => ({
            title: Formatter.requestedAmountNeededIn(application_day),
            value: application_day,
        })),
    };

    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Bedrijf*</Typography>

                <AutocompleteField name={'company'} value={data?.company} model={'Company'} onChange={handleFormChange} renderTitle={(option) => option.name} />

                {errors?.company !== undefined && <Typography color={'error.base'}>{errors.company}</Typography>}
            </div>

            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography mt={24}>Gewenst kredietbedrag*</Typography>

                    <CurrencyField name={'requested_amount'} value={data?.requested_amount} error={errors?.requested_amount} onChange={handleFormChange} />

                    {errors?.requested_amount !== undefined && <Typography color={'error.base'}>{errors.requested_amount}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography mt={24}>Omzet laatste boekjaar*</Typography>

                    <CurrencyField name={'revenue_last_fiscal_year'} value={data?.revenue_last_fiscal_year} error={errors?.revenue_last_fiscal_year} onChange={handleFormChange} />

                    {errors?.revenue_last_fiscal_year !== undefined && <Typography color={'error.base'}>{errors.revenue_last_fiscal_year}</Typography>}
                </div>
            </Box>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={24}>Wanneer*</Typography>

                <OptionsField name={'requested_amount_needed_in'} value={data?.requested_amount_needed_in} options={options.application_days} onChange={handleFormChange} />

                {errors?.requested_amount_needed_in !== undefined && <Typography color={'error.base'}>{errors.requested_amount_needed_in}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default ApplicationForm;

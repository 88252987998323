// Custom hook to debounce a value
import { useEffect, useState } from 'react';

const useDebounceValue = (value, delay = 250) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    // Effect to handle debouncing logic
    useEffect(() => {
        // Set a timeout to update the debounced value after the specified delay
        const handler = setTimeout(() => setDebouncedValue(value), delay);

        // Cleanup function to clear the timeout if value or delay changes
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounceValue;

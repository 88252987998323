import Typography from '@/components/v1/Typography.jsx';
import Button from '@/components/v1/Button.jsx';
import Box from '@/components/v1/Box.jsx';
import { LinkIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import { useForm } from '@inertiajs/react';
import ApplicationCollateralObjectSyncToEntityForm from '@/components/v1/forms/ApplicationCollateralObjectSyncToEntityForm.jsx';
import Modal from '@/components/v1/Modal.jsx';

const ApplicationCollateralObjectSyncToEntityModal = ({ onClose, collateral_object, presets }) => {
    const form = useForm();
    const [processing, setProcessing] = useState(false);
    const handleApplicationDelete = (event) => {
        event.preventDefault();
        setProcessing(true);
        form.submit(
            'post',
            route('collateral-objects.add-to-entity', {
                collateral_object: collateral_object.id,
            }),
            {
                onSuccess: () => onClose(),
                onFinish: () => setProcessing(false),
                preserveScroll: true,
            },
        );
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={processing} />

            <Typography className={'text-xl font-bold'} textAlign={'center'}>
                Eigendom koppelen
            </Typography>

            <Typography textAlign={'center'}>Weet je zeker dat je dit eigendom wilt koppelen?</Typography>

            <ApplicationCollateralObjectSyncToEntityForm form={form} mt={24} onSubmit={handleApplicationDelete} />

            <Box mt={24} display={'flex'} justifyContent={'center'} gridGap={24}>
                <Button onClick={handleApplicationDelete}>
                    <LinkIcon width={24} />
                    <span className={'font-bold'}>Eigendom koppelen</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default ApplicationCollateralObjectSyncToEntityModal;

import { layout, space } from 'styled-system';
import styled from 'styled-components';

const TextAreaField = styled.textarea`
    display: flex;
    align-items: center;
    padding: 16px;
    height: 160px;
    width: 100%;
    background: ${({ theme: { colors } }) => colors.background.variant};
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error.base : theme.colors.grey[1])};
    border-radius: 0.5rem;
    overflow: hidden;
    appearance: none;
    transition: all linear 100ms;
    resize: none;
    ${space}
    ${layout}

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primary.base};
    }
`;

TextAreaField.defaultValues = {
    rows: 10,
};

export default TextAreaField;

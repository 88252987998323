import TextField from '../fields/TextField.jsx';
import Typography from '../Typography.jsx';
import Box from '../Box.jsx';
import { useMemo } from 'react';

const EntityForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Naam*</Typography>

                <TextField name={'name'} type={'text'} value={data?.name} error={errors?.name} onChange={handleFormChange} />

                {errors?.name && <Typography color={'error.base'}>{errors.name}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={24}>Maximale obligaties*</Typography>

                <TextField name={'max_bonds'} type={'number'} value={data?.max_bonds} error={errors?.max_bonds} onChange={handleFormChange} />

                {errors?.max_bonds && <Typography color={'error.base'}>{errors.max_bonds}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default EntityForm;

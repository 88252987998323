import { useState } from 'react';

const Tooltip = ({ children, title }) => {
    const [active, setActive] = useState(false);

    const handleMouseEnter = () => {
        setActive(true);
    };

    const handleMouseLeave = () => {
        setActive(false);
    };

    return (
        <div className={'relative cursor-pointer hover:scale-95'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {active && (
                <div className={'absolute bottom-[calc(100%+.5rem)] left-0 rounded-md bg-primary-500 p-2'}>
                    <p className={'whitespace-nowrap text-xs'}>{title}</p>
                </div>
            )}

            {children}
        </div>
    );
};

export default Tooltip;

import Modal from '@/components/v1/Modal';
import Typography from '@/components/v1/Typography';
import Button from '@/components/v1/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/v1/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import LoadingOverlay from '@/components/v1/overlays/LoadingOverlay.jsx';
import PersonForm from '@/components/v1/forms/PersonForm.jsx';

const PersonEditorModal = ({ onClose, presets, person, company, hidden }) => {
    const initialValues = {
        company_id: company?.id ?? person?.company_id,
        salutation: presets.salutations[0],
        ...person,
    };

    const form = useForm(initialValues);

    const handlePersonEditor = (event) => {
        event.preventDefault();

        if (person) {
            form.submit('put', route('companies.people.update', { company: person.company_id, person: person.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            form.submit('post', route('companies.people.store', { company: company.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Persoon {person ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een persoon in.</Typography>

            <PersonForm className={'mt-5'} form={form} company={company} presets={presets} hidden={hidden} onSubmit={handlePersonEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handlePersonEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Persoon {person ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default PersonEditorModal;

import styled from 'styled-components';
import { color, display, fontSize, fontWeight, space, textAlign } from 'styled-system';

const Typography = styled.p`
    ${display}
    ${space}
    ${color}
    ${fontSize}
    ${fontWeight}
    ${textAlign}
`;

export default Typography;
